<template>
  <div>
    <!-- section-12  -->
    <v-container class="mb-60" name="produtos-em-destaque">
      <!-- <div v-if="loading" class="mt-12 text-center">
        <v-progress-circular size="60" indeterminate color="primary" />
      </div> -->
      <v-row v-if="!loading">
        <v-col cols="12">
          <v-card-title>
            <v-breadcrumbs class="text-uppercase font-weight-bolder" :items="itemsLink" />
          </v-card-title>

          <v-card-text class="ps-6 d-flex justify-space-between align-center flex-wrap">
            <div class="my-2">
              <h4 class="text-uppercase">Produtos</h4>
              <p class="gray--text text--darken-1 mb-0" v-if="produtos.length > 1">
                <span class="font-weight-bold">1 - {{ produtos.length }}</span>
                Produtos listados de
                <span class="font-weight-bold"> {{ total }} </span>
              </p>

              <p class="gray--text text--darken-1 mb-0" v-else>
                <span class="font-weight-bold"> {{ produtos.length }}</span>
                Produto listado
              </p>
            </div>
            <div class="d-flex align-center flex-wrap">
              <div class="grey--text text--darken-1 me-2 my-2">
                Ordenar por:
              </div>

              <v-autocomplete class="border me-5 mt-6" outlined dense :items="ordersBy" item-value="valor" item-text="designacao" placeholder="Padrão" @change="orderByParams()" v-model="params" />
              <div v-if="isWeb" class="grey--text text--darken-1 me-2 my-2">Ver:</div>
              <v-btn v-if="isWeb" @click="showDefaultList('default')" title="Ver Produtos em Forma de Grade" icon>
                <img v-svg-inline class="icon" src="@/assets/images/icons/grid.svg" alt="" />
              </v-btn>
              <v-btn v-if="isWeb" @click="showDefaultList('list')" title="Ver Produtos em Forma de Lista" icon>
                <img v-svg-inline class="icon" src="@/assets/images/icons/menu.svg" alt="" />
              </v-btn>
            </div>

          </v-card-text>

          <v-row v-if="!loading">
            <v-col cols="12" md="12">
              <div class="text-center">
                <v-pagination v-model="page" :length="last_page" :total-visible="7" @input="getAllProdutosPaginate(page)" color="primary"></v-pagination>
              </div>
            </v-col>
          </v-row>
          <v-row class="mb-6">
            <v-divider />
          </v-row>
          <v-container v-if="defaultLayout == 'default'">
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="3" xl="2" v-for="(item, index) in produtos" :key="index">
                <CardCart cardHoverShadow :content-img="item.imagem_produto" :content-text="item.designacao" :amount="item.pvp" :produto="item" />
              </v-col>
            </v-row>
          </v-container>

          <v-container v-else>
            <v-row name="list-products">
              <v-col cols="12">
                <v-card elevation="0" v-for="produto in produtos" :key="produto.id" class="mb-2">
                  <v-card-text class="ps-6 d-flex justify-space-between align-center flex-wrap">
                    <router-link class="custom-link" :to="{
                        name: 'ProdutoDetalhes',
                        params: { produto_id: produto.uuid },
                      }">
                      <v-list-item>
                        <v-list-item-avatar size="80" :title="produto.designacao" rounded>
                          <v-img :alt="produto.designacao" :src="SERVER + produto.imagem_produto"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title style="max-width: 700px" class="font-weight-bold text-uppercase d-inline-block text-truncate">{{ produto.designacao }}</v-list-item-title>
                          <v-list-item-subtitle class="text-subtitle2 font-weight-medium">
                            {{ formatePrice(produto.pvp) }}
                            AKZ (IVA incluido)</v-list-item-subtitle>
                          <v-list-item-subtitle>
                            <v-rating :value="
                                produto.classificacao.num_classificacao ==
                                  null ||
                                !produto.classificacao.num_classificacao
                                  ? 5
                                  : parseInt(
                                      produto.classificacao.num_classificacao
                                    )
                              " color="amber" background-color="amber" dense half-increments readonly size="12"></v-rating>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </router-link>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>

          <v-row v-if="!loading">
            <v-col cols="12" md="12">
              <div class="text-center">
                <v-pagination v-model="page" :length="last_page" :total-visible="7" @input="getAllProdutosPaginate(page)" color="primary"></v-pagination>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog name="dialog-loading-dados" v-model="loading" max-width="300" persistent>
      <v-card>
        <v-card-text class="text-center">
          <v-progress-circular size="30" class="mt-6" indeterminate color="secondary" />
          <v-col cols="12">
            <span class="font-weight-medium"> Carregando dados...</span>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CardCart from "@/components/cartCard/CardCart";
import { LOGIN_URL, BASE_URL, SERVIDOR } from "@/configs/api";
import axios from "axios";

export default {
  components: {
    CardCart,
  },
  data() {
    return {
      isWeb:false,
      SERVER: SERVIDOR,
      total: 0,
      last_page: 1,
      defaultLayout: "default",
      itemsLink: [
        {
          text: "Início",
          disabled: false,
          href: "/",
        },
        {
          text: " Todos Produtos",
          disabled: true,
          href: "/",
        },
      ],

      params: "",
      ordersBy: [
        // {
        //   id: "min",
        //   designacao: "Preço: o mais barato primeiro",
        // },
        // {
        //   id: "max",
        //   designacao: "Preço: o mais caro primeiro",
        // },
        // {
        //   id: "asc",
        //   designacao: "Nome de Produto: A a Z",
        // },
        // {
        //   id: "desc",
        //   designacao: "Nome de Produto: Z a A",
        // },
      ],
      loading: false,
      produtos: [],
      tab: null,
      page: 1,
      itemsPerPage: 8,
      items: [
        {
          img: require("@/assets/images/banners/cycle.png"),
          title: "Scarlett Beauty",
          avatar: require("@/assets/images/faces/2.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner.png"),
          title: "Scroll Through",
          avatar: require("@/assets/images/faces/3.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-3.png"),
          title: "Coveted Clicks",
          avatar: require("@/assets/images/faces/4.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-4.png"),
          title: "Constant Shoppers",
          avatar: require("@/assets/images/faces/5.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-5.png"),
          title: "Keyboard Kiosk",
          avatar: require("@/assets/images/faces/6.png"),
        },
        {
          img: require("@/assets/images/banners/banner-6.png"),
          title: "Anytime Buys",
          avatar: require("@/assets/images/faces/9.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-7.png"),
          title: "Word Wide Wishes",
          avatar: require("@/assets/images/faces/4.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-8.png"),
          title: "Cybershop",
          avatar: require("@/assets/images/faces/3.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-9.png"),
          title: "Cybershop",
          avatar: require("@/assets/images/faces/6.png"),
        },
        {
          img: require("@/assets/images/banners/banner.png"),
          title: "Scroll Through",
          avatar: require("@/assets/images/faces/3.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-3.png"),
          title: "Coveted Clicks",
          avatar: require("@/assets/images/faces/4.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-4.png"),
          title: "Constant Shoppers",
          avatar: require("@/assets/images/faces/5.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-5.png"),
          title: "Keyboard Kiosk",
          avatar: require("@/assets/images/faces/6.png"),
        },
        {
          img: require("@/assets/images/banners/banner-6.png"),
          title: "Anytime Buys",
          avatar: require("@/assets/images/faces/9.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-7.png"),
          title: "Word Wide Wishes",
          avatar: require("@/assets/images/faces/4.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-8.png"),
          title: "Cybershop",
          avatar: require("@/assets/images/faces/3.jpg"),
        },
        {
          img: require("@/assets/images/banners/banner-9.png"),
          title: "Cybershop",
          avatar: require("@/assets/images/faces/6.png"),
        },
      ],
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    // this.getAllProdutos();
     this.isWeb = this.$vuetify.breakpoint.name === 'xl' || this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'md'
   
  },
  mounted() {
    this.getAllProdutosPaginate();
    this.getOrdersLabels();
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    getOrdersLabels() {
      axios
        .get(
          `${LOGIN_URL}/listarOrderByProdutos`
        )
        .then((response) => {
          this.ordersBy = response.data;
        })
        .catch((error) => {
          console.log(`error ${error}`);
        });
    },
    formatePrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    showDefaultList(params) {
      this.defaultLayout = params;
    },

    orderByParams() {
      let params = this.params;
      axios
        .get(`${BASE_URL}/listarProdutos?orderBy=${params}`)
        .then((response) => {
          this.produtos = response.data.data;
        })
        .catch((error) => {
          console.log(`error ${error}`);
        });
    },
    getAllProdutos() {
      this.loading = true;
      // getUtilizadores(page = 1) {
      // "/api/busca-utilizadores?page=" + page
      axios
        .get(`${BASE_URL}/listarProdutos`)
        .then((response) => {
          this.produtos = response.data.data;
          // this.last_page = response.data.last_page
          // this.total = response.data.total
          setTimeout(() => {
            this.loading = false;
          }, 50);
          // alert(JSON.stringify(this.produtos.length));
        })
        .catch((error) => {
          console.log(`error ${error}`);
        });
    },

    getAllProdutosPaginate(page = 1) {
      axios
        .get(`${BASE_URL}/listarProdutos?page=${page}`)
        .then((response) => {
          this.produtos = response.data.data;
          this.last_page = response.data.last_page;
          this.total = response.data.total;

          // setTimeout(() => {
          //     this.loading = false;
          // }, 1500);
          // alert(JSON.stringify(this.produtos));
        })
        .catch((error) => {
          console.log(`error ${error}`);
        });
    },

    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.vendor-card {
  .vendor-avatar {
    position: absolute;
    top: -28px;
    left: 20px;

    img {
      border: 4px solid #fff;
    }
  }

  .vendor-btn {
    position: absolute;
    bottom: 6px;
  }
}
</style>
